"use client";

import { useFormikContext } from "formik";
import Link from "next/link";

import { SITE_NAME } from "@shared/constants";

import Field from "@client/components/formik/field";
import Input from "@client/components/formik/input";
import Phone from "@client/components/formik/phone-number";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";
import TrustedForm from "@client/components/trusted-form";

type Props = {
  baseUrl?: string;
};

export default function PhoneNumber({ baseUrl = "" }: Props) {
  const { values } = useFormikContext<any>();

  const btnText = values.submitMethod === "submit" ? "Submit" : "Continue";

  return (
    <>
      <Input type="hidden" name="agreesToTCPA" id="agrees-to-tcpa" />
      <Fieldset
        dialog="Your phone number enables direct communication for timely updates and support regarding your debt solutions.  We ask this so that we, our partners and their affiliated companies can contact you about the products and services you inquired about, even if your telephone number is listed on any Do-Not Call list. Contact may be made through automatic dialing systems, artificial or prerecorded voice messaging, or text message."
        dialogHeader="Why do we need your phone number?"
        legend="What is your phone number?"
      >
        <Field label="Phone Number" name="phoneNumber">
          <Phone />
        </Field>
      </Fieldset>
      <Button />
      <div className="form__consents" data-spec-lead-form-consents>
        <p>
          By clicking <b className="uppercase">{btnText}</b> above, you
          understand and agree to the following:
        </p>
        <p>
          You are providing your express written consent for{" "}
          {SITE_NAME == "American Loan Assistance"
            ? `${SITE_NAME} (CPC Marketing)`
            : SITE_NAME}{" "}
          and its{" "}
          <Link href={`${baseUrl}/providers`} target="_blank">
            Providers
          </Link>{" "}
          to call and/or text you at the number above, including through
          automated means (e.g. autodialing and pre-recorded messaging) for
          transactional purposes as well as for marketing purposes such as to
          offer you products and services that may serve your financial needs.
        </p>
      </div>
      <TrustedForm />
    </>
  );
}
